import {
  getRemoteConfig as getFirebaseRemoteConfig,
  fetchAndActivate,
  getAll,
  isSupported,
} from 'firebase/remote-config'
import { toBoolean } from 'fitify-utils/src/properties'
import { useEffect, useState, createContext } from 'react'

export const useRemoteConfig = (rcDefaults: RemoteConfig): RemoteConfig => {
  const isRemoteConfigFetchEnabled = toBoolean(
    process.env.NEXT_PUBLIC_REMOTE_CONFIG_FETCH_ENABLED
  )

  const [config, setConfig] = useState<RemoteConfig>(rcDefaults)

  useEffect(() => {
    const fetchData = async () => {
      const data = await getRemoteConfig()
      if (Object.keys(data).length) setConfig(data)
    }

    if (isRemoteConfigFetchEnabled) void fetchData()
  }, [isRemoteConfigFetchEnabled])

  return config
}

export async function getRemoteConfig(): Promise<Record<string, any>> {
  const isRemoteConfigSupported = await isSupported()

  // If remote config is not supported, return empty object
  if (!isRemoteConfigSupported) {
    return {}
  }

  const firebaseRemoteConfig = getFirebaseRemoteConfig()

  await fetchAndActivate(firebaseRemoteConfig)
  const configParams = getAll(firebaseRemoteConfig)

  const remoteConfigProps: RemoteConfig = {}
  for (const key of Object.keys(configParams)) {
    const value: any = configParams[key]
    remoteConfigProps[key] = value._value
  }

  return remoteConfigProps
}

type Await<T> = T extends Promise<infer U> ? U : never

export type RemoteConfig = Await<ReturnType<typeof getRemoteConfig>>
export const RemoteContext = createContext<RemoteConfig>({} as RemoteConfig)
