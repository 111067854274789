/* eslint @typescript-eslint/no-explicit-any: 'off' */

export const toBoolean = (value: any): boolean => {
  return /^(true|1)$/i.test(value)
}

const parseNumber = (
  value: any,
  parser: (value: string) => any
): number | null => {
  let parsedNumber: number | undefined

  switch (true) {
    case typeof value === 'number':
      parsedNumber = value
      break
    case typeof value === 'string':
      parsedNumber = parser(value)
      break
    default:
      break
  }

  return typeof parsedNumber === 'number' && !isNaN(parsedNumber)
    ? parsedNumber
    : null
}

export const parseInteger = (value: any): number | null => {
  const parserFn = (parseValue: string) => parseInt(parseValue, 10)
  return parseNumber(value, parserFn)
}

export const parseFloatNumber = (value: any): number | null => {
  return parseNumber(value, parseFloat)
}
