export const ELEMENT_IDS = {
  home: 'home',
  reviews: 'reviews',
  tools: 'tools',
  contact: 'contact',
  frequentlyAskedQuestions: 'frequently-asked-questions',
  features: 'features',
  howItWorks: 'how-it-works',
  pricing: 'pricing',
  navigation: 'navigation',
  mobileAnimation: 'mobile-animation',
}
