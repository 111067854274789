import { useEffect, useRef } from 'react'
import smartlookClient from 'smartlook-client'

export const useSmartlook = (isEnabled: boolean): null => {
  const isSmartlookLoaded = useRef(false)

  useEffect(() => {
    if (isEnabled && isSmartlookLoaded.current === false) {
      smartlookClient.init(process.env.NEXT_PUBLIC_SMARTLOOK_API_KEY as string)

      smartlookClient.record({
        forms: true,
        numbers: true,
        emails: true,
      })

      isSmartlookLoaded.current = true
    }
  }, [isEnabled])

  return null
}
