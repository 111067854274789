import merge from 'lodash/merge'
import { ReactNode } from 'react'
import {
  type DefaultTheme,
  type RuleSet,
  ThemeProvider,
} from 'styled-components'

import { baseTextVariants } from '../Text/Text.Styled'

import { colors, ThemeColors } from './colors'

export enum ThemeVariants {
  HC = 'hc',
  DIGITAL = 'digital',
}

export interface CustomTheme {
  variant?: ThemeVariants
  colors: Record<ThemeColors, string>
  textVariants: Record<string, RuleSet>
}

export const baseTheme: DefaultTheme = {
  colors: colors,
  textVariants: baseTextVariants,
}

export const FitifyThemeProvider = ({
  children,
  theme,
}: {
  children?: ReactNode | undefined
  theme: DefaultTheme
}) => {
  const composedTheme = merge({}, baseTheme, theme)
  return <ThemeProvider theme={composedTheme}>{children}</ThemeProvider>
}
