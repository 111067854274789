export default {
  title: 'Fitify App: AI fitness plans & workouts',
  description:
    'Get your custom fitness plan tailored to your goals and lifestyle, and enjoy the most personalized workout experience! Get your custom fitness plan tailored to your goals and lifestyle, and enjoy the most personalized workout experience!',
  openGraph: {
    type: 'website',
    locale: 'en',
    url: '#',
    site_name: 'Fitify App: AI fitness plans & workouts',
    images: [
      {
        url: 'https://static.gofitify.com/images/og/fitify-digital-og-image.png',
      },
    ],
  },
  twitter: {
    handle: '',
    site: '',
    cardType: 'summary_large_image',
  },
}
