import { deviceSize, ThemeColors } from 'fitify-ui'
import { css, type RuleSet } from 'styled-components'

export const xsPhone = (inner: RuleSet) => css`
  @media (max-width: ${deviceSize.xsPhone}px) {
    ${inner};
  }
`

export const phone = (inner: RuleSet) => css`
  @media (max-width: ${deviceSize.phoneUpperBoundary}px) {
    ${inner};
  }
`

export const biggerPhoneLandscape = (inner: RuleSet) => css`
  @media (max-height: ${deviceSize.biggerPhoneUpBoundary}px) {
    ${inner};
  }
`

export const biggerPhone = (inner: RuleSet) => css`
  @media (max-width: ${deviceSize.biggerPhoneUpBoundary}px) {
    ${inner};
  }
`
export const biggerPhoneUp = (inner: RuleSet) => css`
  @media (min-width: ${deviceSize.phoneUpperBoundary + 1}px) {
    ${inner};
  }
`
export const tabletPortrait = (inner: RuleSet) => css`
  @media (max-width: ${deviceSize.tabletPortraitUpperBoundary}px) {
    ${inner};
  }
`
export const tabletPortraitUp = (inner: RuleSet) => css`
  @media (min-width: ${deviceSize.biggerPhoneUpBoundary}px) {
    ${inner};
  }
`
export const tabletLandscape = (inner: RuleSet) => css`
  @media (max-width: ${deviceSize.tabletLandscapeUpperBoundary}px) {
    ${inner};
  }
`
export const tabletLandscapeUp = (inner: RuleSet) => css`
  @media (min-width: ${deviceSize.tabletPortraitUpperBoundary}px) {
    ${inner};
  }
`
export const desktopUp = (inner: RuleSet) => css`
  @media (min-width: ${deviceSize.tabletLandscapeUpperBoundary}px) {
    ${inner};
  }
`
export const bigDesktop = (inner: RuleSet) => css`
  @media (min-width: ${deviceSize.desktopUpperBoundary}px) {
    ${inner};
  }
`

export interface StyledAsProps {
  as?: React.ElementType | keyof JSX.IntrinsicElements
  color?: ThemeColors
}

function getMediaQuery(size: number) {
  class MediaQuery {
    size = 0
    gte = ''
    lte = ''

    constructor(size: number) {
      this.size = size
      this.gte = `(min-width: ${this.size}px)`
      this.lte = `(max-width: ${this.size}px)`
    }
  }

  return new MediaQuery(size)
}

export const BREAKPOINTS = {
  XS: getMediaQuery(320), // small phone
  SM: getMediaQuery(480), // phone
  MD: getMediaQuery(768), // tablet
  LG: getMediaQuery(1024), // desktop
  XL: getMediaQuery(1440), // large desktop
}

export function pxToRem(px: number): number {
  return px / 16
}
